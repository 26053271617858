<template>
  <div class="mini-card-slider-container">
    <ClientOnly>
      <Carousel
        ref="carousel"
        v-bind="carouselProps"
        @slide-start="beforeSlide"
        @slide-end="afterSlide"
      >
        <Slide
          v-for="(card, index) in cards"
          :key="card.id"
        >
          <Card
            v-if="animatedItem"
            :card="card"
            data-aos="fade"
            :data-aos-duration="600 * index"
            :dragging="dragging"
          />
          <Card
            v-else
            :card="card"
            :dragging="dragging"
          />
        </Slide>
        <template #addons>
          <div
            class="custom-arrow slick-arrow slick-prev"
            :class="{ disabled: isFirstSlide }"
            @click="prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              ></path>
            </svg>
          </div>
          <div
            class="custom-arrow slick-arrow slick-next"
            :class="{ disabled: isLastSlide }"
            @click="next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              ></path>
            </svg>
          </div>
        </template>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { CarouselInstance, CarouselProps } from '@/types'
import { Carousel, Slide } from 'vue3-carousel'

defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cards?: Record<string, any>[]
}>()

const carousel = ref<CarouselInstance>(null)
const dragging = ref(false)
const allowAnim = ref(true)

const { width } = useElementSize(carousel)
const { isMobile, isLargeMobile, isTabletPortrait } = useCustomMediaQuery()

const responsiveWidth = computed(() => {
  if (isMobile.value || isLargeMobile.value || isTabletPortrait.value) {
    return width.value / 296
  }

  return width.value / 350
})

const carouselProps = computed(
  () =>
    ({
      transition: 500,
      itemsToShow: responsiveWidth.value,
      snapAlign: 'start',
    }) satisfies CarouselProps,
)

const animatedItem = computed(() => {
  return (
    allowAnim.value &&
    !(isMobile.value || isLargeMobile.value || isTabletPortrait.value)
  )
})

const carouselData = computed(() => carousel.value?.data)

const isFirstSlide = computed(
  () => carouselData.value && carouselData.value.currentSlide.value === 0,
)

const isLastSlide = computed(
  () =>
    carouselData.value &&
    carouselData.value.currentSlide.value === carouselData.value.maxSlide.value,
)

const prev = () => {
  carousel.value?.prev()
}

const next = () => {
  carousel.value?.next()
}

const beforeSlide = () => {
  dragging.value = true
  allowAnim.value = false
}

const afterSlide = () => {
  dragging.value = false
}
</script>

<style lang="scss">
.mini-card-slider-container {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 700px) {
    .carousel__viewport {
      padding-left: 2.5rem;
    }

    .custom-arrow {
      display: none !important;
    }
  }

  .carousel {
    width: 100%;

    .carousel__slide {
      margin-right: 1rem;
    }

    .slick-next,
    .slick-prev {
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      font-size: 0;
      line-height: 0;

      /* stylelint-disable-next-line declaration-property-value-disallowed-list */
      border: none;
      cursor: pointer;
      transform: translateY(-50%);

      &.disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    .custom-arrow {
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8rem;
      height: 100%;
      background: $white;
      opacity: 1;
      transform: translateX(100%);

      svg {
        width: 2.36rem;
      }

      &::before {
        content: '';
        color: #fff;
        font-family: slick;
        font-size: 20px;
        line-height: 1;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.slick-prev {
        left: 0;
        transform: translateX(-100%);

        svg {
          transform: rotate(180deg);
        }
      }

      &.slick-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
</style>
