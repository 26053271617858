<template>
  <div
    class="challenge-blue-section"
    :class="diagonal"
  >
    <section
      v-if="connected"
      class="blue-section section white connect"
    >
      <div
        class="ranking"
        :class="{ full: challenges.length == 0 }"
      >
        <div
          class="ranking-content"
          :class="{ alone: challenges.length == 0 }"
        >
          <RankingMini
            :ranks="rankingStore.top"
            title="Votre classement"
          />
        </div>
      </div>
      <div
        v-if="challenges.length"
        class="monthly-challenge"
      >
        <div class="diagonal">
          <div class="top-blue" />
        </div>
        <div class="monthly-challenge-content">
          <div class="title-line line">
            <h2 class="title-strat">{{ title }}</h2>
            <NuxtLink
              to="/challenges"
              class="button-more"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19.328 34.43"
              >
                <path
                  d="M165.21-130.78l-15.05-15.1a2.178,2.178,0,0,0-3.041,0,2.178,2.178,0,0,0,0,3.041l13.555,13.555L147.119-115.73a2.178,2.178,0,0,0,0,3.041,2.178,2.178,0,0,0,3.041,0l15.05-15.05A2.178,2.178,0,0,0,165.21-130.78Z"
                  transform="translate(-146.5 146.5)"
                  fill="#FFFFFF"
                  fill-rule="evenodd"
                />
              </svg>
              <h3>Plus de challenges</h3>
            </NuxtLink>
          </div>
          <div class="slider">
            <MiniCardSlider :cards="challenges" />
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      class="blue-section section white no-connect"
    >
      <div class="join-community">
        <JoinSection />
      </div>
      <div class="top-waper section">
        <div class="title-line line">
          <h2 class="title-strat">top 3 des wapers</h2>
          <div
            class="button-more"
            @click="openRanking"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
            >
              <path
                d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
                fill="#fff"
                fill-rule="evenodd"
              />
            </svg>
            Voir le classement complet
          </div>
        </div>
        <div class="slider">
          <TopWaper :top="top" />
        </div>
      </div>
      <CardSection
        v-if="challenges.length"
        class="monthly-challenge"
        title="Les challenges du mois"
        cta-label="Plus de challenges"
        cta-route="/challenges"
        :cards="challenges"
        color="white"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { rankingModal } from '~/modals/ranking'
import type { CustomChallenge } from '~/types'

const { gtag } = useGtag()

const rankingStore = useRankingStore()
withDefaults(
  defineProps<{
    connected: boolean
    challenges?: CustomChallenge[]
    diagonal?: string
    title?: string
  }>(),
  {
    challenges: () => [],
    diagonal: '',
    title: 'Les challenges du mois',
  },
)

const top = computed(() => {
  return {
    period: new Date(),
    wapers: rankingStore.top,
  }
})

const openRanking = () => {
  gtag('event', 'cta', {
    cta_name: 'Voir le classement complet',
  })
  rankingModal.open()
}
</script>

<style lang="scss" scoped>
.challenge-blue-section {
  .section {
    width: 100%;
  }

  &.top {
    &.white {
      .blue-section {
        padding-top: 5rem;

        .monthly-challenge {
          margin-top: -5rem;
          padding-top: 13rem;
        }

        &.no-connect {
          padding-top: 13rem;

          .monthly-challenge {
            margin-top: 5rem;
            padding-top: 0;
          }
        }
      }

      .blue-section::after {
        top: 0;
        border-width: 5rem 100vw 0 0;
        border-color: $white transparent transparent transparent;
      }
    }

    &.dark {
      .blue-section {
        padding-top: 5rem;

        .monthly-challenge {
          margin-top: -5rem;
          padding-top: 8rem;
        }

        &.no-connect {
          padding-top: 8rem;

          .monthly-challenge {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }

      .blue-section::after {
        top: 0;
        border-width: 5rem 100vw 0 0;
        border-color: $darkBackground transparent transparent transparent;
      }
    }

    .blue-section::after {
      position: absolute;
      top: -5rem;
      right: 0;
      z-index: 2;
      width: 0;
      height: 0;
      border-width: 0 0 5rem 100vw;
      border-style: solid;
      border-color: transparent transparent $blue transparent;
      content: '';
    }

    &.transparent {
      .diagonal {
        position: absolute;
        top: -5rem;
        left: 0;
        z-index: 3;
        display: inline-block;
        width: 100%;
        height: 5rem;
        overflow: hidden;

        .top-blue {
          position: absolute;
          top: 0;
          left: -56.5rem;
          z-index: 2;
          width: 0;
          height: 0;
          border-width: 0 0 5rem 100vw;
          border-style: solid;
          border-color: transparent transparent $challengeBackgroundBlue
            transparent;
        }
      }

      .blue-section::after {
        top: -5rem;
        z-index: 2;
        border-width: 0 0 5rem 100vw;
        border-color: transparent transparent $blue transparent;
      }
    }

    .blue-section.no-connect {
      .diagonal {
        display: none;
      }
    }
  }

  &.bottom {
    .blue-section {
      &.connect {
        .ranking-content {
          &.alone {
            margin-bottom: 5rem;
          }
        }
      }

      &.no-connect {
        padding-bottom: 8rem;

        .monthly-challenge {
          padding-bottom: 0;
        }
      }

      .monthly-challenge {
        padding-bottom: 13rem;
      }
    }
  }

  .blue-section::after {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 0;
    height: 0;
    border-width: 0 0 5rem 100vw;
    border-style: solid;
    content: '';
  }

  &.white {
    .blue-section::after {
      border-color: transparent transparent $white transparent;
    }
  }

  &.dark {
    .blue-section::after {
      border-color: transparent transparent $darkBackground transparent;
    }
  }
}

.blue-section {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100vw;
  margin-top: 0;
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  &.no-connect {
    display: block;
    padding-top: 8rem;
    padding-bottom: 8rem;

    .monthly-challenge {
      width: 100%;
      margin-top: 8rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8rem;
      background: none;
    }
  }

  .join-community {
    max-width: 200rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0 2rem 0 8rem;
  }

  .top-waper {
    .title-line {
      .button-more {
        color: $white;
        cursor: pointer;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .ranking {
    width: 56.5rem;
    height: 100%;
    padding-top: 4rem;

    &.full {
      width: 100%;
      margin-bottom: 6rem;
    }
  }

  .monthly-challenge {
    position: relative;
    width: calc(100% - 56.5rem);
    height: 100%;
    margin-top: 0;
    padding-top: 8.5rem;
    padding-bottom: 9rem;
    padding-left: 12rem;
    background: $challengeBackgroundBlue;

    .line {
      padding-right: 8rem;

      .title-strat {
        font-family: 'SST Condensed';
        font-size: 2.6rem;
        font-weight: 400;
        line-height: 4.9rem;
        letter-spacing: 0.52rem;
      }
    }

    .slider {
      width: 100%;
      padding-right: 8rem;
    }
  }
}

@media screen and (max-width: 1225px) {
  .challenge-blue-section {
    &.top {
      &.transparent {
        .connect {
          .ranking {
            padding-top: 5rem;
          }
        }

        .diagonal {
          display: none;
        }
      }

      &.white {
        .blue-section {
          .monthly-challenge {
            margin-top: 0;
          }
        }
      }

      &.dark {
        .blue-section {
          .monthly-challenge {
            margin-top: 0;
          }
        }
      }
    }

    &.bottom {
      .blue-section {
        &.connect {
          .ranking-content {
            &.alone {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .connect {
      flex-direction: column;

      .ranking {
        width: 100%;
        padding-top: 10rem;
        padding-bottom: 10rem;
      }

      .monthly-challenge {
        width: 100%;
        padding-left: 8rem;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .challenge-blue-section {
    .no-connect {
      .top-waper {
        padding-right: 0;

        .title-line {
          padding-right: 8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .challenge-blue-section {
    .no-connect {
      .top-waper {
        padding-right: 8rem;

        .title-line {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .challenge-blue-section {
    &.transparent {
      .section:first-child {
        margin-top: 0;
      }
    }

    .section {
      margin-top: 5rem;

      &.blue-section.no-connect:not(.top) {
        margin-top: 0;
        padding-top: 5rem;
      }
    }

    .connect {
      .monthly-challenge {
        padding-top: 7rem !important;
        padding-left: 0 !important;

        .line {
          padding-right: 7rem;
          padding-left: 7rem;

          .button-more {
            flex-shrink: 0;
          }
        }

        .slider {
          padding-right: 0;

          .slick-list {
            padding-left: 2.5rem;
          }
        }
      }
    }

    .no-connect {
      .join-community {
        padding-left: 2.5rem;
      }

      .top-waper {
        padding-right: 2.5rem;

        .title-line {
          padding-left: 0;

          .button-more {
            flex-shrink: 0;
            font-size: 1rem;
          }
        }
      }

      .monthly-challenge {
        padding-right: 0;
        padding-left: 0;

        .title-line {
          .button-more {
            flex-shrink: 0;
            font-size: 1rem;
          }
        }

        .slider {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .challenge-blue-section {
    width: 100%;

    &.top {
      &.transparent {
        .diagonal {
          .top-blue {
            left: calc(-1 * (50vw - 100rem + 56.5rem));
          }
        }
      }
    }

    section {
      max-width: 100%;
    }

    .blue-section {
      .ranking.full {
        max-width: 200rem;
        margin-right: auto;
        margin-left: auto;

        .alone {
          width: 100%;
        }
      }

      &.no-connect {
        .monthly-challenge {
          display: block;
          width: 100%;
        }
      }
    }

    .ranking {
      display: flex;
      justify-content: flex-end;
      width: calc(50vw - 100rem + 56.5rem);

      .ranking-content {
        width: 56.5rem;
      }
    }

    .monthly-challenge {
      display: flex;
      justify-content: flex-start;
      width: calc(50vw - 100rem + 143.5rem);

      .monthly-challenge-content {
        width: 132.5rem;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .challenge-blue-section {
    &.top.dark .blue-section .monthly-challenge {
      padding-top: 6rem;
    }

    .connect {
      .monthly-challenge {
        padding-top: 6rem;
        padding-bottom: 7rem;

        .line {
          align-items: flex-end;
          margin-bottom: 2.5em;
          padding-right: 2.5rem;
          padding-left: 2.5rem;

          .title-strat {
            font-size: 2rem;
            line-height: 2.7rem;
            letter-spacing: 0.3rem;
          }

          .button-more {
            font-size: 1rem;
            line-height: 2.7rem;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 700px) {
  .challenge-blue-section {
    .connect {
      .monthly-challenge {
        .slider {
          .slick-list {
            padding-left: 7rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .challenge-blue-section {
    .connect {
      .monthly-challenge {
        .slider {
          .slick-list {
            padding-left: 2.5rem;
          }
        }
      }
    }
  }
}
</style>
