<template>
  <div class="join-container">
    <ClientOnly>
      <Carousel
        v-if="rtbSLider.length"
        ref="carousel"
        v-bind="carouselProps"
        @slide-end="(data) => onSlideEnd(data as SlideEndEvent)"
      >
        <Slide
          v-for="(step, index) in rtbSLider"
          :key="step.title"
          class="slide-content"
        >
          <div class="text-slide">
            <h2 v-html="step.title" />
            <div v-html="step.content" />
          </div>
          <NuxtImg
            v-if="step.image"
            :src="step.image?.url"
            :alt="step.title"
            class="img-slider"
            :class="`img-join${index}`"
            width="414"
            :height="imageHeight"
            quality="80"
          />
        </Slide>
        <template #addons="{ slidesCount, currentSlide }">
          <ul class="slick-dots">
            <li
              v-for="slide in slidesCount"
              :key="slide"
              :class="{ 'slick-active': currentSlide === slide - 1 }"
              @click="slideTo(slide)"
            >
              <div class="custom-dot">
                {{ slide }}
              </div>
            </li>
          </ul>
        </template>
      </Carousel>
    </ClientOnly>
    <div class="buttons-line">
      <ButtonSimple
        label="S'inscrire"
        color="white"
        route="/inscription"
        @click="
          gtag('event', 'clicinscription', {
            cta_name: `hpbas_S'inscrire`,
          })
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel'
import type { CarouselInstance, CarouselProps, SlideEndEvent } from '~/types'

const crossContentStore = useCrossContentStore()

const { gtag } = useGtag()

const carousel = ref<CarouselInstance>(null)

const rtbSLider = computed(
  () => crossContentStore.crossContent?.rtbSLider ?? [],
)

const carouselProps = {
  autoplay: 10000,
  transition: 800,
  pauseAutoplayOnHover: true,
} satisfies CarouselProps

const imageHeight = '500'
const imageHeightStyle = `${imageHeight}px`

const transition = `${carouselProps.transition}ms`

const onSlideEnd = ({ currentSlideIndex, slidesCount }: SlideEndEvent) => {
  if (currentSlideIndex !== slidesCount - 1) return
  setTimeout(() => {
    carousel.value?.slideTo(0)
  }, carouselProps.autoplay)
}

const slideTo = (index: number) => {
  carousel.value?.slideTo(index - 1)
}
</script>

<style lang="scss" scoped>
.join-container {
  position: relative;
  width: 100%;

  .buttons-line {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -6rem;

    .how-it-works {
      margin-left: 7.7rem;
      color: $white;
      font-size: 1.6rem;
      text-decoration: underline;
    }

    @media screen and (max-width: 1250px) {
      margin-top: 6.5rem;
    }

    @media screen and (max-width: 1024px) {
      margin-top: 6.5rem;
    }

    @media screen and (max-width: 900px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 625px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &:deep(.carousel) {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 0;

    @media screen and (max-width: 625px) {
      margin-top: 2rem;
    }

    .carousel__track {
      display: flex !important;
      height: 100%;
      transform: translateX(0) !important; // Disable translate on inline style
    }

    .carousel__viewport {
      height: v-bind(imageHeightStyle);
    }

    .carousel__slide {
      position: absolute;
      display: flex !important;
      height: 100%;
      opacity: 0;
      transition: v-bind(transition);
    }

    .carousel__slide--active {
      opacity: 1;
    }

    h2 {
      margin-bottom: 3.4rem;
      color: $white;
      font-family: 'SST Condensed';
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 4.8rem;
      letter-spacing: 0.68rem;
    }

    .slide-content {
      display: flex !important;
      justify-content: space-between;
      align-items: flex-start;

      .text-slide {
        width: 58.9rem;
        padding-top: 0;
        font-weight: 300;
        text-align: left;

        p {
          font-size: 2.2rem;
          line-height: 3.4rem;
        }
      }

      .img-slider {
        display: block;
        width: 41.4rem;
        height: 50rem;
        object-fit: contain;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 11rem;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      list-style: none;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 30px;
        z-index: -1;
        width: 100%;
        height: 1px;
        background: $secondaryBlue;

        @media (min-width: 901px) {
          right: 8rem;
          width: 100vw;
        }
      }

      li {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
      }

      .slick-active {
        .custom-dot {
          color: $blue;
          background: $white;
        }
      }
    }

    .custom-dot {
      z-index: 1;
      height: 6rem;
      padding: 0 4.8rem;
      color: $white;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 6rem;
      background: $secondaryBlue;
    }

    @media screen and (max-width: 1250px) {
      .slide-content {
        .img-slider {
          width: 30.2rem;
          height: 40rem;
          object-position: bottom;
        }
      }

      .slick-dots {
        bottom: 12px;
      }
    }

    @media screen and (max-width: 1024px) {
      .slide-content {
        .img-slider {
          width: 30.2rem;
          height: 32rem;
          padding-top: 7rem;
        }

        .text-slide {
          width: 32rem;

          p {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
      }

      h2 {
        font-size: 2rem;
        line-height: 2.7rem;
        letter-spacing: 0.3rem;
      }

      .slick-dots {
        .custom-dot {
          height: 3.5rem;
          padding: 0 4.2rem;
          font-size: 1rem;
          line-height: 3.5rem;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .slide-content {
        align-items: center;

        .text-slide {
          width: auto;
        }
      }

      .slick-dots {
        position: relative;
        bottom: 10px;
        display: flex !important;
        justify-content: center;
        width: 100%;
        margin-top: 5rem;
      }
    }

    @media screen and (max-width: 625px) {
      .join-slider {
        margin-top: 2rem;
      }

      h2 {
        width: 100%;
      }

      .slide-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .text-slide {
          width: 100%;
          padding-top: 0;
        }

        .img-slider {
          width: 75vw;
          height: auto;
          padding-top: 0;
        }
      }

      .slick-dots {
        justify-content: flex-start;
        width: 100vw;
        margin-left: -2.5rem;
        padding: 0 2.5rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none; // Safari and Chrome
        }

        li:first-child {
          padding-left: 2.5rem;
        }

        li:last-child {
          padding-right: 3.5rem;
        }
      }
    }
  }
}
</style>
